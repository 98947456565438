import '../stylings/nopage.css';

export default function NoPage()
{
    return(
        <>
        <div className='np-main'>
            <div className='np-main-center'>
                <div className='np-main-img'>
                    <img src='/imgs/skull.png' alt='404 Not Found' id='skull'/>
                </div>
                <div className='np-center-content'>
                    <h2 className='section-heading'>OOPS!</h2>
                    <p>You Came To The Wrong Neighborhood!</p>
                    <button id='safe-home'><a href='/'>Find your way home</a></button>
                </div>
            </div>
        </div>
        </>
    );
}