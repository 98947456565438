import {useEffect} from 'react';
export default function Schedule(){
    useEffect(()=>{
        const events = document.querySelectorAll(".schedule-event");
        for(let i=0;i<events.length;i++){
            events[i].setAttribute("data-aos","fade-up");
            // events[i].setAttribute("data-aos-delay","200");
        }
        const th = document.getElementsByTagName("th");
        for(let i=0;i<th.length;i++){
            th[i].setAttribute("data-aos","fade-right");
            th[i].setAttribute("data-aos-delay","100")
        }
        const td = document.getElementsByTagName("td");
        for(let i=0;i<td.length;i++){
            td[i].setAttribute("data-aos","fade-in");
        }
    },[])
    return(
        <>
            <h1 className="section-heading" style={{textAlign:'center',margin:'20px auto'}}>Schedule</h1>
            <div className="schedule-div">
                <h3>Day - 1</h3>
                <table data-aos="fade-up" data-aos-delay="100">
                    <tbody>
                    <tr style={{backgroundColor:'#171716'}}>
                        <th style={{width:'15%'}} id="table-time-heading">
                            Time
                        </th>
                        <th style={{width:'85%'}} id="table-events-heading">Events</th>
                    </tr>
                    <tr>
                        <th>9:00 am</th>
                        <td><p className="schedule-event common-col" data-tooltip="Venue: Auditorium (Apex Block - 7th Floor)">Inauguration</p></td>
                    </tr>
                    <tr>
                        <th>11:30 am</th>
                        <td>
                            <p className="schedule-event event7" data-tooltip="Round No: 1&#10;Venue: LAB 2">UG Quiz</p>
                            <p className="schedule-event event9" data-tooltip="Round No: 1&#10;Venue: LAB 1">PG Coding</p>
                            <p className="schedule-event event2" data-tooltip="Round No: 1&#10;Venue: AB 402">Photography</p>
                            <p className="schedule-event event11" data-tooltip="Round No: 1&#10;Venue: LAB 4">PG Web Development</p>
                            <p className="schedule-event event3" data-tooltip="Round No: 1&#10;Venue: AB 411">Beg Borrow Steal</p>
                            <p className="schedule-event event4" data-tooltip="Round No: 1&#10;Venue: SPDC">Gaming</p>
                            <p className="schedule-event event8" data-tooltip="Round No: 1&#10;Venue: LAB 3 (ESB-402)">UG Web Development</p>
                            <p className="schedule-event event1" data-tooltip="Round No: 1&#10;Venue: Hi-tech Seminar Hall (DES Block)">IT Manager</p>
                        </td>
                    </tr>
                    <tr>
                        <th>2:00 pm</th>
                        <td><p className="schedule-event common-col" data-tooltip="Venue: Apex Block - 7th floor">LUNCH</p></td>

                    </tr>
                    <tr>
                        <th>2:30 pm</th>
                        <td>
                            <p className="schedule-event event6" data-tooltip="Round No: 1&#10;Venue: LAB 1">UG Coding</p>
                            <p className="schedule-event event10" data-tooltip="Round No: 1&#10;Venue: LAB 2">PG Quiz</p>
                            <p className="schedule-event event2" data-tooltip="Round No: 2&#10;Venue: AB 402">Photography</p>
                            <p className="schedule-event event11" data-tooltip="Round No: 2&#10;Venue: LAB 4">PG Web Development</p>
                            <p className="schedule-event event3" data-tooltip="Round No: 2&#10;Venue: AB 411">Beg Borrow Steal</p>
                            <p className="schedule-event event4" data-tooltip="Round No: 2&#10;Venue: SPDC">Gaming</p>
                            <p className="schedule-event event8" data-tooltip="Round No: 2&#10;Venue: LAB 3">UG - Web Development</p>
                            <p className="schedule-event event1" data-tooltip="Round No: 2&#10;Venue: Hi-tech Seminar Hall (DES Block)">IT Manager</p>
                        </td>
                    </tr>
                    </tbody>    
                </table>
                <h3>Day - 2</h3>
                <table>
                    <tbody>
                    <tr>
                        <th>9:30 am</th>
                        <td>
                        <p className="schedule-event event6" data-tooltip="Round No: 2&#10;Venue: LAB 1">UG Coding</p>
                        <p className="schedule-event event4" data-tooltip="Round No: 3&#10;Venue: SPDC">Gaming</p>
                        <p className="schedule-event event2" data-tooltip="Round No: 3&#10;Venue: LAB 4">Photography</p>                        
                        <p className="schedule-event event5" data-tooltip="Round No: 1&#10;Venue: AB 402">Treasure Hunt</p>
                        <p className="schedule-event event1" data-tooltip="Round No: 3&#10;Venue: Hi-tech Seminar Hall (DES Block)">IT Manager</p>
                        <p className="schedule-event event8" data-tooltip="Round No: 3&#10;Venue: LAB 3">UG Web Development</p>
                        <p className="schedule-event event7" data-tooltip="Round No: 2&#10;Venue: LAB 2">UG Quiz</p>
                        <p className="schedule-event event3" data-tooltip="Round No: 3&#10;Venue: AB 411">Beg Borrow Steal</p>
                        </td>
                    </tr>
                    <tr>
                        <th>10:30 am</th>
                        <td>
                            <p className="schedule-event event11" data-tooltip="Round No: 3&#10;Venue: LAB 4">PG Web Development</p>
                        </td>
                    </tr>
                    <tr>
                        <th>11:30 am</th>
                        <td>
                            <p className="schedule-event event9" data-tooltip="Round No: 2&#10;Venue: LAB 1">PG Coding</p>
                            <p className="schedule-event event10" data-tooltip="Round No: 2&#10;Venue: LAB 2">PG Quiz</p>
                        </td>
                    </tr>
                    <tr>
                        <th>12:00 pm</th>
                        <td>
                        <p className="schedule-event event2" data-tooltip="Round No: 3&#10;Venue: LAB 3">Photography</p>
                        </td>
                    </tr>
                    <tr>
                        <th>2:00 pm</th>
                        <td>
                        <p className="schedule-event common-col" data-tooltip="Venue: Apex Block - 7th floor">LUNCH</p>
                        </td>
                    </tr>
                    <tr>
                        <th>3:00 pm</th>
                        <td>
                        <p className="schedule-event common-col" data-tooltip="Round No: 1&#10;Venue: Auditorium (Apex Block - 7th Floor)">Valedictory</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </>
    )
}