import { useEffect } from "react";

export default function Hero(){
    const counting = false;
    var endDate = new Date(2024, 3, 19, 9, 0, 0).getTime();
  useEffect(() => {
    const id = setInterval(() => {
        
        if(counting){var now =  new Date().getTime();
    
        var timeRemaining = endDate - now;
    
        var daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        var hoursRemaining = Math.floor((timeRemaining % (1000 * 60 * 60 * 24))/(1000 * 60 * 60));
        var minutesRemaining = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        var secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / (1000));
    
        document.getElementById("days").innerHTML = daysRemaining;
        document.getElementById("hours").innerHTML = hoursRemaining;
        document.getElementById("minutes").innerHTML = minutesRemaining;
        document.getElementById("seconds").innerHTML = secondsRemaining; 
    
        if (timeRemaining < 0) { 
            clearInterval(id);
            document.getElementById("days").innerHTML ='0'; 
            document.getElementById("hours").innerHTML ='0'; 
            document.getElementById("minutes").innerHTML ='0' ; 
            document.getElementById("seconds").innerHTML = '0';
            // alert("Thank you for your patience");
        }
    }
    }, 1000);
    // setIntervalId(id);

    return () => clearInterval(id); // Cleanup function
  }, [endDate,counting]); // Empty dependency array to run effect only once
    
    return(
        <>
        <div id="hero">
        <div className="background-gradient"/>
            <h1 id="title" data-aos="slide-up">Abhyuday 8.0</h1>
            {counting?<>
            
            <div className="time" data-aos="fade" data-aos-delay="500">
                <p id="days" className="timer colon" />
                <p id="hours" className="timer colon" />
                <p id="minutes" className="timer colon" />
                <p id="seconds" className="timer" />
                <p className="heading">Days</p>
                <p className="heading">Hours</p>
                <p className="heading">Minutes</p>
                <p className="heading">Seconds</p>
            </div>
            <div data-aos="fade" data-aos-delay="500">
                <h2 className="hero-event-details">
                Date: April 19, 2024 <br />
                Time: 9:00 AM <br /><br />
                Venue : MCA Dept. MSRIT
                </h2>
            </div>
            </>
            :(
                <>
                <div style={{textAlign:'center',margin:'5% auto',padding:'15px',backdropFilter:'blur(5px)',width:'fit-content',backgroundColor:'rgb(0,0,0,0.5',borderRadius:'10px'}}>
                    <h2 style={{margin:'5% 2%'}}>The Event is Over &#128516;</h2>
                    <p>Hope to see you soon!!</p>
                    <br/> 
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                    <p>Abhyuday 9.0  </p>
                    <div className="loader" style={{margin:'15px'}}></div>
                    
                    </div>
                </div>

                </>
            )}
        </div>
        </>
    )
}