import UgEvents from "../components/UgEvents.js";
import PgEvents from "../components/PgEvents.js";
import CommonEvents from "../components/CommonEvents.js";
import { useState } from "react";

function  Home()
{   
    const [selectedComponent, setSelectedComponent] = useState('common');

    const handleButtonClick = (componentName) => {  
      setSelectedComponent(componentName);
    };
    return(
       <>
        <div id="homebody" data-aos="fade-in" data-aos-delay="100">
            
            <div id="theme" data-aos="slide-up">
                {/* <h2 style={{position:"absolute",top:"0",zIndex:"2"}} className="section-heading">Theme</h2> */}
                <div className="scroll">
                <img src="imgs/carousel-imgs/char1.jpg" alt="" />
                <img src="imgs/carousel-imgs/char2.jpg" alt="" />
                <img src="imgs/carousel-imgs/char3.jpg" alt="" />
                <img src="imgs/carousel-imgs/char4.jpg" alt="" />
                <img src="imgs/carousel-imgs/char5.jpg" alt="" />
                <img src="imgs/carousel-imgs/char6.jpg" alt="" />
                <img src="imgs/carousel-imgs/char7.jpg" alt="" />
                <img src="imgs/carousel-imgs/char8.jpg" alt="" />
                <img src="imgs/carousel-imgs/char9.jpg" alt="" />
                <img src="imgs/carousel-imgs/char10.jpg" alt="" />
                <img src="imgs/carousel-imgs/char11.jpg" alt="" />
                <img src="imgs/carousel-imgs/char12.jpg" alt="" />
                <img src="imgs/carousel-imgs/char13.jpg" alt="" />
                </div>
                <div className="scroll">
                <img src="imgs/carousel-imgs/char1.jpg" alt="" />
                <img src="imgs/carousel-imgs/char2.jpg" alt="" />
                <img src="imgs/carousel-imgs/char3.jpg" alt="" />
                <img src="imgs/carousel-imgs/char4.jpg" alt="" />
                <img src="imgs/carousel-imgs/char5.jpg" alt="" />
                <img src="imgs/carousel-imgs/char6.jpg" alt="" />
                <img src="imgs/carousel-imgs/char7.jpg" alt="" />
                <img src="imgs/carousel-imgs/char8.jpg" alt="" />
                <img src="imgs/carousel-imgs/char9.jpg" alt="" />
                <img src="imgs/carousel-imgs/char10.jpg" alt="" />
                <img src="imgs/carousel-imgs/char11.jpg" alt="" />
                <img src="imgs/carousel-imgs/char12.jpg" alt="" />
                <img src="imgs/carousel-imgs/char13.jpg" alt="" />
                </div>
                <div className="theme-text" data-aos="fade-down">
                <p>Join us, as we embark on an exciting journey into the enchanting realm of <br /> <span style={{color:"red",fontFamily:"avatar"}}>Anime</span></p>
                <p>Engage with fellow fans, explore iconic series and characters, and immerse yourself in the vibrant spirit of Anime throughout the fest.</p>
                </div>      
            </div>




            <div id="events" data-aos="fade-up" data-aos-delay="100">
                <h2 className="section-heading">Events Organized</h2>


                <div className='event-list' data-aos="fade-left">
                    <div className='event-buttons'>
                        <button className={`eBtn ${selectedComponent === 'common' ? 'active' : ''}`} id="b1" onClick={()=>handleButtonClick('common')}><span>Common Events</span></button>
                        <button className={`eBtn ${selectedComponent === 'ug' ? 'active' : ''}`} id="b2" onClick={()=>handleButtonClick('ug')}><span>UG Events</span></button>
                        <button className={`eBtn ${selectedComponent === 'pg' ? 'active' : ''}`} id="b3" onClick={()=>handleButtonClick('pg')}><span>PG Events</span></button>
                    </div>
                    <div className='card-container' data-aos="fade-left" data-aos-delay="200">
                    {selectedComponent === 'common' && <CommonEvents/>}
                    {selectedComponent === 'ug' && <UgEvents/>}
                    {selectedComponent === 'pg' && <PgEvents/>}
                    </div>
                </div>
            </div>





            {/* <div id="video"></div> */}
            <div id="map" data-aos="fade-up" data-aos-delay="100">
                <h2 className="section-heading">Venue</h2>
                <iframe data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200" title="Venue" style={{filter:"invert(90%)"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d485.8849634729066!2d77.564764!3d13.030596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17defe1b5ef3%3A0xa3816b895cee0ada!2sMCA%20Department!5e0!3m2!1sen!2sin!4v1711994629398!5m2!1sen!2sin"  allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
            {/* <div id="sponsors">
                <h2 className="section-heading">Our Sponsors</h2>
            </div> */}
            <div id="host" data-aos="fade-up" data-aos-delay="100" >
                <div className="background-gradient"/>
                <div className="host-section" data-aos="fade-in" data-aos-duration="1000">
                <h2>Organized By</h2>
                <h2 style={{margin:"10px"}} data-aos="fade" data-aos-delay="100">Department of MCA</h2>
                <div style={{display:"grid",gridTemplateColumns:"auto auto auto",margin:"auto",justifyContent:"space-around",alignItems:"center",justifyItems:"center"}}>
                    <div><img src="imgs/Centenary Logo.png" className="logos" alt="" /></div>
                    <div><img src="imgs/brand.png" alt="" style={{width:"100%",maxWidth:"250px",backgroundColor:"black",borderRadius:"12px",padding:"12px"}} className="logos" /></div>
                    <div><img src="imgs/Diamond Jubilee.png" className="logos" alt=""/></div>
                </div>
                </div>
            </div>
            <div id="contact">
                <h1 className="section-heading" data-aos="fade-left" data-aos-delay="100">Head of Department</h1>
                <div>
                <div className="contact" data-aos="fade-in" data-aos-delay="200">
                    <div className="dp"><img src="imgs/hod.jpg" alt="" /></div>                    
                    <p>Dr. Monica R. Mundada</p>
                    {/* <h3>9620293370</h3> */}
                </div>
                </div>
                <h1 className="section-heading" data-aos="fade-right" data-aos-delat="100">Faculty Coordinators</h1>
                <div>
                <div className="contact" data-aos="fade-in" data-aos-delay="200">
                    <div className="dp"><img src="imgs/faculty.jpeg" alt="" /></div>                    
                    <p>Dr. D. Evangelin Geetha</p>
                    {/* <h3>9620293370</h3> */}
                </div>
                </div>
                <h1 className="section-heading" data-aos="fade-left" data-aos-delat="100">Student Coordinators</h1>
                <div className="students">
                <div className="contact" data-aos="fade-in" data-aos-delay="200">
                    <div className="dp"><img src="imgs/saniya.jpeg" alt="" /></div>
                    <p>Saniya Farheen</p>
                    {/* <h3>8217688007</h3> */}
                </div>
                <div className="contact" data-aos="fade-in" data-aos-delay="300">
                    <div className="dp"><img src="imgs/pavan.jpeg" alt="" /></div>
                    <p>Pavan R Shetty</p>
                    {/* <h3>8660053550</h3> */}
                </div>
                <div className="contact" data-aos="fade-in" data-aos-delay="300">
                    <div className="dp"><img src="imgs/tarushi.jpeg" alt="" /></div>
                    <p>Tarushi</p>
                    {/* <h3>7899257241</h3> */}
                </div>
                <div className="contact" data-aos="fade-in" data-aos-delay="200">
                    <div className="dp"><img src="imgs/kaif.jpeg" alt="" /></div>
                    <p>Mohammad Kaif</p>
                    {/* <h3>8971951990</h3> */}
                </div>
                </div>
            </div>
        </div>
       </>
    );
}

export default Home;