import React,{ useState,useRef, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import ReactDOMServer from 'react-dom/server';
import DOMPurify from 'dompurify';

export default function Chat(){
    const  [loading,setLoading]=useState(false)
    const [chatActive,setChatActive] = useState(false);
    const toggleChat = ()=>{
        setChatActive(!chatActive);
    }
    useEffect(() => {
        if (chatActive) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [chatActive]);
    const [query, setQuery] = useState({});
    const handleQueryForm=(e)=>{
        // eslint-disable-next-line no-unused-vars
        var name= e.target.name;
        const value = e.target.value;
        setQuery({name:value});
    }
    const chatBodyRef = useRef(null);
    // const htmlEntities = {
    //     "-": "&#8209;", // Non-breaking hyphen
    //     "–": "&#8211;", // En dash
    //     "'": "&#39;",   // Apostrophe
    //     " ": "&nbsp;"   // Non-breaking space
    // };

    const handleChatSubmit = async (e) => {
        e.preventDefault();
        e.target.reset();
        e.target.disabled=true;
        var userInput = DOMPurify.sanitize(query.name);
        userInput = userInput.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        // Create a new message element
        var toMessage = document.createElement('div');
        toMessage.classList.add('chat-msg');
        var toMessage1 = document.createElement('div');
        toMessage1.classList.add('to-msg');
        toMessage1.innerHTML = `<p>${userInput}</p>`;
        toMessage.appendChild(toMessage1);
        chatBodyRef.current.appendChild(toMessage);
        
        setLoading(true);
        const fromMessage = document.createElement('div');
        fromMessage.classList.add('chat-msg');
        const fromMessage1 = document.createElement('div');
        fromMessage1.classList.add('from-msg');
        const response = document.createElement('p');
        response.innerHTML="<span class='dot'>.</span><span class='dot'>.</span><span class='dot'>.</span>";
        response.classList.add("loading-dots")
        fromMessage1.appendChild(response);
        fromMessage.appendChild(fromMessage1)
        chatBodyRef.current.appendChild(fromMessage);
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        try {
            // Simulating loading delay
            
            const response = await fetch('https://iot.surajg.tech/process', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: userInput }),
            });
            const data = await response.json();
            // var formattedText = data.response.replace(/[-' ]/g, match => htmlEntities[match]);
            const reactMarkdownElement = React.createElement(ReactMarkdown, null, data.response);
            const htmlContent = ReactDOMServer.renderToString(reactMarkdownElement);
            fromMessage1.innerHTML=htmlContent
            // fromMessage1.innerHTML = `<p>${data.response}</p>`;
            
        } catch (error) {
            response.innerHTML="An error occured!!";
            response.style.color='red';

            console.error('Error:', error);
            // Handle error
        } finally {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            e.target.disabled=false;
            setLoading(false);
            
        }
    };

    return(
        <>
        <div className="chat-section">
                    <div className={`chat-window${chatActive?' chat-open':''}`}>
                    {chatActive && (<>
                        
                        <div className="chat-header">
                                <div className="chat-dp-div"><img src="/imgs/namiassistant.png" alt="chat-dp"/></div>
                                <p>Nami</p>
                        </div>
                            <div className="chat-body" ref={chatBodyRef}>
                                
                            </div>
                            <div className="chat-input">
                                <form onSubmit={handleChatSubmit}>
                                    <input type="text" name="query" autoFocus autoComplete="off" onChange={handleQueryForm} placeholder="Your query here" required/>
                                  { loading? <input type="submit" value="&#10147;" disabled/>:
                                    <input type="submit" value="&#10147;"/>}
                                </form>
                            </div>
                        
                    </>)}
                    </div>
            <div className="chat-icon" style={chatActive?{width:'45px',height:'45px',bottom:'-25px',right:'-25px'}:{}}  onClick={toggleChat}>
                
                {!chatActive && (
                    <>
                    <p>Ask Me!!</p>
                    <img src="/imgs/namiassistant.png" alt="chat-icon"/>
                    </>


                )}
                {chatActive && (
                    <div className="chatcross change" >
                        <div className="bar1"/>
                        <div className="bar2"/>
                        <div className="bar3"/>
                    </div>)}
            </div>
        </div>
        </>
    )
}