export default function Footer(){
    return(
        <div className="site-footer">
            <img src="/imgs/bullet.png" alt="" className="circles"/>
            <div className="footer-content">
                <h2>Join Us at Abhyuday 8.0!</h2>
                <p>Experience the thrill of competition and the joy of camaraderie.</p>
                <div className="social-icons">
                <a href="https://www.instagram.com/abhyuday_8.0" rel="noreferrer" target="_blank"><img src="/imgs/instagram.png" alt="Instagram" /></a>
                <a href="https://www.youtube.com/@Abhyudhay2k24" rel="noreferrer" target="_blank"><img src="/imgs/youtube.png" alt="Youtube" /></a>
                <a href="https://wa.me/919535745513" rel="noreferrer" target="_blank"><img src="/imgs/whatsapp.png" alt="Whatsapp" /></a>

                </div>
                <p style={{fontSize:'16pt',marginTop:'10px'}}>Made with <span style={{color:'red'}}>&#10084;</span> By <a style={{textDecoration:'underline',fontSize:'16pt'}} href="https://pratheeka-2001.github.io/" target="_blank" rel="noreferrer">Pratheek</a> and <a style={{textDecoration:'underline',fontSize:'16pt'}} href="https://ravindravithlani.github.io/" target="_blank" rel="noreferrer">Ravindra</a>.</p>
            </div>
            <img src="/imgs/bullet.png" alt="" className="circles"/>  
        </div>
    )
}