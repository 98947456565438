export default function Guidelines(){
    return(
        <div id="guidelines">
            <h2 className="section-heading" data-aos="fade-down">General Guidelines</h2>
            <ol data-aos="fade-up" data-aos-delay="200">
                <li>The fest is open for all BCA and MCA students.</li>
                <li>The participants are required to bring their valid college ID.</li>
                <li>The events will be conducted only if there is a minimum of five teams per event.</li>
                <li>Refer the website for Schedule and Venues of your respective events.</li>
                <li>The registration counter will be open from 8:30 am to 9:30 am. <br/> Participants are requested to report to the registration desk on time. On-spot registrations are allowed</li>
                <li>Registration fee is non-refundable.</li>
                <li>For any queries regarding events, registration etc. Please contact our support team. You can also utilise our chatbot on the website.</li>
                <li>The decision of the jury will be final and binding on the participants.</li>
                <li>The organizers reserve the right to reschedule or cancel any event(s) if the situation warrants.</li>
                <li>It is advisable to every participant to participate in one event only, to avoid clashes of event timings.</li>
                <li>Disqualified players can join other events if the schedule allows.</li>
                <li>No smoking, drugs, alcohol, or misbehaviour. Disqualification for any violations.</li>
                <li>No phones or electronic gadgets are allowed during the event. Any participant(s) engaging in practices that violates the rules of the events shall be disqualified.</li>
                <li>Lunch will be provided on both days.</li>
                <li>MSRIT is not responsible for the loss of any personal belongings during the event.</li>
            </ol>
        </div>
    )
}