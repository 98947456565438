import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user); 
      setIsLoading(false); 
    });

    return unsubscribe; 
  }, []);

  if (isLoading) {
    return <div></div>; 
  }

  return isLoggedIn ? children : <Navigate to="/signin" replace />;
}

export default ProtectedRoute;
