import React from 'react';
import { useLocation,Outlet } from 'react-router-dom';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Chat from './components/Chat';

function Layout() {
    const location = useLocation();

    return (
        <>
            {location.pathname === '/' && <Hero />} {/* Render Hero component only if current route is home */}
            <div id="body">
                <Chat/>
                <NavBar />
                <Outlet />
                <Footer />
            </div>
        </>
    );
}

export default Layout;
