export default function Popup({children, show}){
    return(
        <div id="popup" className={`popup`}>
                
                <div className='popup-div'>
                    <p id='close' onClick={askEmail} style={{position:'absolute',right:'5px',top:'0px',fontSize:'12pt',cursor:'pointer'}}>&#10060;</p>
                    {children}
                    
                </div>

            </div>
    )
}

export function askEmail() {
    // Show popup
    const popup = document.getElementById('popup');
    popup.classList.toggle('popup-display')
    document.body.classList.toggle("no-scroll");
}
