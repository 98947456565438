import Card from "./Card";

function CommonEvents()
{
    return(
        <>
            <Card name="Kibutsuji's Mugen Jo" commonName="IT Manager" number="1" index={1}/>
            <Card name="Studio Ghibli's Gaze" commonName='Photography' number="1" index={2}/>
            <Card name='Hunter x Hunter' commonName="Beg, Borrow, Steal" number="2" index={3}/>
            <Card name='Isekai Battle Royale' commonName="Gaming" number="4" index={4}/>
            <Card name='One Piece' commonName="Treasure Hunt" number="3" index={5}/>
        </>
    );
}

export default CommonEvents;