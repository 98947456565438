import Card from "./Card";
function PgEvents()
{
    return(
        <>
            <Card name="Akira's CodeCon" commonName="Coding and Debugging(PG)" number="2" index={9}/>
            <Card name='Chishiki Test' commonName="IT Quiz(PG)" number="2" index={10}/>
            <Card name='Web Sozo' commonName="Web Designing(PG)" number="2" index={11}/>
        </>
    );
}

export default PgEvents;