export default function EventDes(props){
    return(
        <>
        {
            props.index === '1' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>This is an individual event.</li>
                <li>Number of rounds - 4</li>
                <li>The participants of IT Manager event are restricted from participating in any other event</li>
                <li>Disqualified participants are not owed any explanation.</li>
                <li>Adherence to these rules is mandatory.</li>
                <li>Non-compliance may result in disqualification.</li>
                <li>Registration Fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '2' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>This is an individual event.</li>
                <li>Number of rounds – 3</li>
                <li>The photos that are submitted to this contest must be taken within the campus.</li>
                <li>Modification other than basic adjustment such as cropping, brightness and contrast are not allowed.</li>
                <li>Any electronic device required should be brought by the participant.</li>
                <li>Registration Fee - Rs. 200/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '3' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>Number of participants per team : 2</li>
                <li>Number of rounds : 3</li>
                <li>Event details will be explained on the day of event.</li>
                <li>"Prepare yourself to Hunt or be Hunted"</li>
                <li>Registration fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '4' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>Number of participants per team: 4.</li>
                <li>There will be 5 matches (3- Erangel 1- Sanhok 1- Miramar).</li>
                <li>Wifi will be provided by the college.</li>
                <li>Each player should have their own device.</li>
                <li>All the IG names (In Game Names) should match with the names given during registration (NOTE: If IG Names are different the whole team is disqualified).</li>
                <li>Player Should download all the maps and Resource pack prior to the event.</li>
                <li>Prizes will be rewarded based on the highest points scored by the team.</li>
                <li>In case of any hacks or malfunctions used in game, the whole team will be disqualified from the event.</li>
                <li>Registration Fee - Rs. 400/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '5' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>Number of participant per team : 3</li>
                <li>Number of rounds - 2</li>
                <li>Participants are not allowed to search for clues in off-limit areas.</li>
                <li>Smart Phones are not allowed.</li>
                <li>Registration Fee - Rs. 400/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '6' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>Number of Participants per team – 2</li>
                <li>Number Of Rounds – 3</li>
                <li>Knowledge of any of these programming languages is mandatory: C, C++, Java</li>
                <li>Registration Fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '7' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>No. of participant per team : 2</li>
                <li>Number Of Rounds – 3</li>
                <li>Each round would have a specific time limit.</li>
                <li>Knowledge of Programming languages, Operating system, Data Structures and Algorithms, Networking, Information Security and related subjects.</li>
                <li>Registration fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '8' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>No. of participant per team : 2</li>
                <li>Number Of Rounds – 3</li>
                <li>Each round would have a specific time limit.</li>
                <li>Knowledge of HTML, CSS, and JavaScript is mandatory.</li>
                <li>Registration Fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '9' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>Number of participants per team - 2</li>
                <li>Number Of Rounds – 3</li>
                <li>Knowledge in any of the following programming languages is mandatory C, C++, Java</li>
                <li>Registration Fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '10' &&
            (
                <>
                
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>Number of participants per team - 2</li>
                <li>Number Of Rounds – 3</li>
                <li>Each round will have a specific time limit.</li>
                <li>Knowledge of Programming languages, Operating system, DSA, Networking, Information Security, Machine learning and related subjects.</li>

                <li>Registration Fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        {
            props.index === '11' &&
            (
                <>
            
            <div id="rules">
                <h2>Rules:</h2>
                <ul>
                <li>No. of participants per team : 2</li>
                <li>Number Of Rounds – 3</li>
                <li>Each round will have a specific time limit.</li>
                <li>Knowledge of HTML, CSS(Animation, hover, transition), JavaScript, and one of the backend frameworks (NodeJS, Django, PHP, Flask, Ruby on Rails, ASP.NET) and RDBMS databases.</li>
                <li>Registration Fee - Rs. 300/-</li>
                </ul>
            </div></>
            )
        }
        
        </>
    )
}