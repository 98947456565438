import { useState, useEffect } from 'react';

import { doc, getDoc, collection, getDocs } from 'firebase/firestore'; 
import { getAuth } from 'firebase/auth'; 
import { onAuthStateChanged } from 'firebase/auth';
import {  db } from '../Firebase/firebase';

export default function Dashboard(){
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    const [isLoading, setIsLoading] = useState(true); 
    const [userData, setUserData] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [announcements, setAnnouncements] = useState({})

    //Getting user info
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setIsLoggedIn(!!user);
            
          if (user) {
            fetchUserData(user.email);
          } else {
            setIsLoading(false); 
          }
        });
    
        return unsubscribe;
      }, []);

      const fetchUserData = async (userEmail) => {
        try {
          const userDocRef = doc(db, 'Users', userEmail);
          const userDocSnap = await getDoc(userDocRef);

          
          if (userDocSnap.exists()) {
            //user data
            setUserData(userDocSnap.data()); 

            //Get Event Data
            const userEventDocRef = doc(db, 'events', userDocSnap.data().eventName);
            const userEventDocSnap = await getDoc(userEventDocRef);
            setEventData(userEventDocSnap.data());

            //Get Announcements
            const announcementsRef = collection(db, 'events', userDocSnap.data().eventName, 'announcements');
            const announcementsSnapshot = await getDocs(announcementsRef);
            for(let i = 0; i < announcementsSnapshot.docs.length; i++)
            {
                let tempData = announcementsSnapshot.docs[i].data();
                const dictKey = 'a' + i;
                for(let key in tempData)
                {
                    setAnnouncements(values=>({...values, [dictKey]:tempData[key]}))
                }
            }
          } else {
            console.error('User data not found!');
          } 
        } catch (error) {
          console.error(error);
          alert("Error Loading Data")
        } finally {
          setTimeout(()=>{
            setIsLoading(false); 
          }, 1500);
        }
      };

    return(
        <>
        {isLoading ? (
            <div 
            style={{height:'70vh',backgroundColor:'black',zIndex:'99', display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img className='loading-png'
                src='/imgs/bullet.png' alt='Loading...'
                >
                </img>
            </div>
            ) : isLoggedIn ? (
                <div>
                    <div className='dashboard-nav'>
                        <h1>Welcome, {userData.name}!</h1>
                        <div className='joinWhatsapp'>
                            <a href={eventData['WhatsApp Group']} target='_blank' rel='noreferrer'>
                            <div className='joinWhatsapp-data'>
                                <div className='joinWhatsapp-data-img'><img src='/imgs/whatsapp.png' alt='WhatsApp' style={{height:'50px',width:'50px'}}/></div>
                                <div>
                                    <h6>Join The Group</h6>
                                    <p>{eventData["Event Name"]}</p>
                                </div>
                            </div> 
                            </a>
                        </div>
                    </div>
                    <div id="dashboard-section">
                        <h2 className="dashboard-heading">
                            Details
                            <hr />
                        </h2>
                        <table className="details-table">
                            <tbody>
                            <tr>
                                <th>Event Name</th>
                                <td>{userData.eventName}</td>
                            </tr>
                            {
                                userData.teamName ? (
                                    <tr>
                                        <th>Team Name</th>
                                        <td>{userData.teamName}</td>
                                    </tr>
                                ) : (
                                    <></>
                                )
                            }
                            <tr>
                                <th>Participant Name</th>
                                <td>{userData.name}</td>
                            </tr>
                            <tr>
                                <th>Participant USN</th>
                                <td>{userData.collegeUSN}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{userData.email}</td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td>{userData.phone}</td>
                            </tr>
                            {
                                userData.participant2Name ? (
                                    <>
                                    <tr>
                                        <th>Participant 2 Name</th>
                                        <td>{userData.participant2Name}</td>
                                    </tr>
                                    <tr>
                                        <th>Participant 2 USN</th>
                                        <td>{userData.participant2USN}</td>
                                    </tr>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                            {
                                userData.participant3Name ? (
                                    <>
                                    <tr>
                                        <th>Participant 3 Name</th>
                                        <td>{userData.participant3Name}</td>
                                    </tr>
                                    <tr>
                                        <th>Participant 3 USN</th>
                                        <td>{userData.participant3USN}</td>
                                    </tr>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                            {
                                userData.participant4Name ? (
                                    <>
                                    <tr>
                                        <th>Participant 4 Name</th>
                                        <td>{userData.participant4Name}</td>
                                    </tr>
                                    <tr>
                                        <th>Participant 4 USN</th>
                                        <td>{userData.participant4USN}</td>
                                    </tr>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                            {
                                userData.IG_Name1 ? (
                                    <>
                                    <tr>
                                        <th>Participant 1 IG Name</th>
                                        <td>{userData.IG_Name1}</td>
                                    </tr>
                                    </>
                                ) : (<></>)
                            }
                            {
                                userData.IG_Name2 ? (
                                    <>
                                    <tr>
                                        <th>Participant 2 IG Name</th>
                                        <td>{userData.IG_Name2}</td>
                                    </tr>
                                    </>
                                ) : (<></>)
                            }
                            {
                                userData.IG_Name3 ? (
                                    <>
                                    <tr>
                                        <th>Participant 3 IG Name</th>
                                        <td>{userData.IG_Name3}</td>
                                    </tr>
                                    </>
                                ) : (<></>)
                            }
                            {
                                userData.IG_Name4 ? (
                                    <>
                                    <tr>
                                        <th>Participant 4 IG Name</th>
                                        <td>{userData.IG_Name4}</td>
                                    </tr>
                                    </>
                                ) : (<></>)
                            }
                            <tr>
                                <th>College</th>
                                <td>{userData.collegeName}</td>
                            </tr>
                            <tr>
                                <th>Graduation</th>
                                <td>{userData.graduation}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{userData.address}</td>
                            </tr>
                            {
                                userData.paymentStatus ? (
                                    <>
                                    <tr>
                                        <th>Payment Status</th>
                                        <td>{userData.paymentStatus}</td>
                                    </tr>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                            
                            </tbody>
                        </table>
                        <h2 className="dashboard-heading">
                            Event Details <hr />
                        </h2>
                        <table className="details-table">
                            <tbody>
                            {Object.entries(eventData).map(([key, value]) => (
                            <tr key={key}> 
                                <td>{key}</td>
                                <td>{value}</td>
                            </tr>
                            ))}
                            </tbody>
                        </table>
                        <h2 className="dashboard-heading">
                            Announcements <hr />
                        </h2>
                        <ol className='details-table dashboard-ol'>
                                {
                                    Object.values(announcements).map((value, index)=>(
                                            <li key={`${index+1}`}>{value}</li>
                                    ))
                                }
                        </ol>
                    </div>

                </div>
            ) : (
                <p>Please log in to view the dashboard.</p>
        )}
        </>
    )
}