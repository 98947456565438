import { Link, useLocation } from 'react-router-dom'

import { getAuth, signOut } from 'firebase/auth'; 
import { auth } from '../Firebase/firebase';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

export default function NavBar(){
    const location = useLocation();
    const mobNav = () => {
        // Check if viewport width is less than a certain value (e.g., 768px for mobile view)
        if (window.innerWidth <= 768) {
            const container = document.getElementsByClassName("container")[0];
            const navItems = document.querySelector(".nav-items");
            container.classList.toggle("change");
            navItems.classList.toggle("show");
    
            // Toggle body scroll
            document.body.classList.toggle("no-scroll");
        }
    }

    const [isActive, setIsActive] = useState(false);
    useEffect(()=>{
        const authUser = getAuth();
        const unsubscribe = onAuthStateChanged(authUser, (user) => {
            setIsActive(!!user);
          });
      
          return unsubscribe;
        }, []);

    //Logout Logic
    const handleLogout = () => {
        signOut(auth).then(() => {
            alert("Logged Out");
        }).catch((error) => {
            console.log(error);
        })
    }

    return(
        <>
            <nav id="nav" className="transparent">
                <div className="container" onClick={mobNav}>
                    <div className="bar1"/>
                    <div className="bar2"/>
                    <div className="bar3"/>
                </div>
                <div className="nav-items">
                
                <img src="/imgs/sword.png" alt="" id="sword" data-aos="slide-right" data-aos-delay="100"/>
                <div id="nav-links" data-aos="zoom-out" data-aos-duration="600" data-aos-delay="300">
                    <img src='/imgs/1logo1.png' alt='' className='nav-logo'/>
                    <Link to="/" data-aos="zoom-out" data-aos-duration="600" data-aos-delay="400" onClick={mobNav}>Home</Link>
                    <a href="/#events" data-aos="zoom-out" data-aos-duration="600" data-aos-delay="500" onClick={mobNav}>Events</a>
                    <Link to="/guidelines"  data-aos="zoom-out" data-aos-duration="600" data-aos-delay="600" onClick={mobNav}>Guidelines</Link>
                    <Link to="/schedule" data-aos="zoom-out" data-aos-duration="600" data-aos-delay="700" onClick={mobNav}>Schedule</Link>
                </div>
                </div>
                
                {
                    location.pathname !== "/dashboard"?
                    (   
                        isActive?(<>
                        <div className="signIn">
                            <Link className='register' to="/dashboard"  data-aos="fade-in">Dashboard</Link>
                        </div>
                        </>):(<>
                        <div className="signIn">
                            <Link to="/signin"  data-aos="fade-in">Sign In</Link>
                            <Link to="/register" className="register" data-aos="fade-in" style={{margin:'0 0 0 10px'}}>Register</Link>
                            
                        </div>
                        </>)
                    ):(
                        <>
                        <div className="signIn">
                            <button className='register' onClick={handleLogout}>Logout</button>
                        </div>
                        </>
                    )
                }

            </nav>
            <header id="header" />
        </>
    )
}