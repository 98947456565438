import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './Layout';
import Event from './pages/Event';
import Dashboard from './pages/Dashboard';
import RegisterUser from './pages/signup/RegisterUser';
import Login from './pages/signup/Login';
import ProtectedRoute from './pages/signup/ProtectedRoute';
import ScrollToTop from './components/ScrollToTop';
import Guidelines from './pages/Guidelines';
import { useEffect } from 'react';
import AOS from 'aos'; // Import AOS library
import 'aos/dist/aos.css'; // Import AOS styles
import NoPage from './pages/NoPage';
import Schedule from './pages/Schedule';

function App() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
    const imgs = document.getElementsByTagName('img');
        for(let i = 0; i < imgs.length; i++) {
            imgs[i].setAttribute("draggable", "false");
        }

  }, []);
  return (
    <>
    <BrowserRouter>
      <ScrollToTop>
      <Routes>  
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>} />
          <Route path="/event/:id" element={<Event/>} />
          <Route path="/register" element={<RegisterUser/>} />
          <Route path='/signin' element={<Login/>}></Route>
          <Route path='/guidelines' element={<Guidelines/>}/>
          <Route path='/schedule' element={<Schedule/>}/>
          <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard/>
              </ProtectedRoute>
          }></Route>
        </Route>
        
        <Route path="*" element={<NoPage/>} />
      </Routes>
      </ScrollToTop>
    </BrowserRouter>
    </>
  );
}

export default App;
