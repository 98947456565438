import { NavLink } from "react-router-dom";

export default function Card(props){
    return(
        <div className="card" data-aos="zoom-in" data-aos-delay={`${(props.index)%6}00`}>
            <div className="card-image" style={{backgroundImage:`url(imgs/${props.index}.png)`}}></div>
            <div className="card-hover">
                <NavLink to={`event/${props.index}`}><p className="card-view-more">View More</p></NavLink>
            </div>
            <div className="card-info">
                <h6 className="event-name">{props.name}</h6>
                <p>{props.commonName}</p>
                <p>No of Participants : {props.number}</p>
                <p>Click to view more..</p>
            </div>
        </div>        
    )
}