import Card from "./Card";
function UgEvents()
{
    return(
        <>
            <Card name='Otaku Codingu' commonName="Coding and Debugging(UG)" number="2" index={6}/>
            <Card name='Quizu Matsuri' commonName="IT Quiz(UG)" number="2" index={7}/>
            <Card name='Dev Shinobi' commonName="Web Designing(UG)" number="2" index={8}/>
        </>
    );
}

export default UgEvents;